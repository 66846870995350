<template>
  <div my-team-toggle-tab class="team-list">
    <div
      v-for="(team, index) in teams"
      :key="'my-team-' + team.teamId"
      :class="['team', { on: value === index }]"
      @click="onChangeTab(index)"
    >
      <ImgLogo
        class="team-image"
        :source="team.imageUrl"
        :title="team.name"
        :hash="`${team.teamId}`"
        :resolution-x="40"
        :resolution-y="40"
      />
      <span>{{ team.name }}</span>
      <ButtonRipple class="btn-direct" @click="moveToTeamHome(team)">
        <em class="hidden-tv-down">팀 바로가기</em> <em class="hidden-tw-up">팀 가기</em>
      </ButtonRipple>
    </div>
  </div>
</template>

<script>
import { historyMove } from '@shared/utils/routerUtils';
import { MAIN_URL } from '@/env';
import ImgLogo from '@/views/components/common/ImgLogo.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';

export default {
  name: 'MyTeamToggleTab',
  components: { ImgLogo, ButtonRipple },
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onChangeTab(index) {
      this.$emit('input', index);
    },
    moveToTeamHome(team) {
      const { teamId } = team;
      historyMove(
        `${MAIN_URL}/teams/${this.$route.params.id}/${teamId}/home`,
      );
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-team-toggle-tab] {
  &.team-list { .flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;
    > div { .rel; .w(49.5%); .h(55); .p(0, 10); .pr(75); .bgc(#d5d5de); .mt(0); .br-t(12); .block; .pointer;
      &:nth-of-type(1) { .mr(0); }
      .team-image { .hide; vertical-align: super; .br(8); }
      > span { .block; .fs(12); .semi-bold; .ellipsis(1); .m(20, 0); .ml(8); .pr(8); }
      .btn-direct { .abs; .rt(10, 16); z-index: 1; .w; .h; .p(5, 10); .fs(12); .br(12); .bgc(#fff); .c(#787781);
        > em { line-height: 1; }
      }
      &:after { .cnt; .abs; .rt(75, 12); .ib; .wh(1, 34); .bgc(rgba(0, 0, 0, 0.1)); }
      &.on { .bgc(#ebebf0);
        > span { .c(#191919); }
      }
    }
  }
  @media (@tl-up) {
    &.team-list { .flex; flex-direction: row;
      > div { .w(49.5%); .h(55); .p(0, 10); .pr(98); .bgc(#d5d5de); .mt(0); .br-t(12);
        &:nth-of-type(1) { .mr(0); }
        .team-image { .ib; }
        > span { .ib; }
        .btn-direct { .bgc(#ebebf0); }
        &:after { .rt(98, 11); .bgc(rgba(0, 0, 0, 0.1)); .bgc(#ebebf0); }
      }
    }
  }
  @media (@ds-up) {
    &.team-list { .pr(300);
      > div { .pr(98);
        .team-image { .ib; }
        > span { .ib; }
        &:after { .rt(98, 11); .bgc(rgba(0, 0, 0, 0.1)); .bgc(#ebebf0); }
      }
    }
  } }
</style>
