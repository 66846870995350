<template>
    <li>
        <b>[{{history.data.title || history.data.name || history.data.nickname}}]</b>{{HISTORY_TEXT[history.event]}}
        <span>{{date}}</span>
    </li>
</template>

<script>
import HISTORY_TEXT from '@/constants/historyText';
import { timeForToday } from "@/utils/time";

export default {
    name:'MyTeamList',
    props: {
        history: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        HISTORY_TEXT: () => HISTORY_TEXT,
        date(){
            return timeForToday(this.history.createdDatetime);
        }
    }
}
</script>

<style lang="less" scoped>

</style>