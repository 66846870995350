<template>
  <em>
    {{remainTime}}
  </em>
</template>

<script>
  import moment from 'moment';
  export default {
    name:'CountDown',
    props: {
      endTime: {
        type: Number,
        default: Date.now() + 10000000
      },
    },
    data() {
      return {
        remainTime: '00 : 00 : 00'
      }
    },
    methods: {
      remainTimeCalc () {
        this.countDown()

        setInterval(() => {
          this.countDown()
        }, 1000)
      },
      countDown () {
        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
        const endtime = moment(moment(this.endTime).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
        const diffTime = moment(endtime).diff(moment(now));

        if (diffTime < 0) {
          this.remainTime = '마감';

          return false;
        }

        const remainTime = moment.duration(diffTime);

        const days = parseInt(remainTime.asDays());
        const hours = parseInt(remainTime.asHours());
        const minutes = parseInt(remainTime.asMinutes()) - (hours * 60);
        const seconds = parseInt(remainTime.asSeconds()) - ((hours * 60 * 60) + (minutes * 60));

        if(days >= 1) {
          this.remainTime = `${days}일 남음`
        }else{
          this.remainTime = hours.toString().padStart(2, '0') + ' : ' + minutes.toString().padStart(2, '0') + ' : ' + seconds.toString().padStart(2, '0');
        }

        if (this.remainTime === '00 : 00 : 00' || days < 0) {
          this.remainTime = '마감';
          clearInterval(this.remainTimeCalc());
        }
      }
    },
    mounted(){
      this.remainTimeCalc();
    },
    destroyed () {
      clearInterval(this.remainTimeCalc())
    }
  }
</script>

<style lang="less" scoped>

</style>