<template>
  <div my-team class="card-holder">
    <div v-if="isLogin && teams && teams.length">
      <MyTeamToggleTab :teams="teams" v-model="selectedIndex" />
      <div class="list-container">
        <ul v-if="histories&&histories.length" class="list-contents">
          <MyTeamList v-for="(history, index) in histories" :key="'history-' + index" :history="history" />
        </ul>
        <NoData v-else src="/img/pages/icon/History.svg" text="로그가 없습니다." />
      </div>
    </div>
    <NoData v-else :src="'/img/pages/icon/info-my.svg'" text="아직 나의 팀이 없나요?<br />나만의 팀을 만들어보세요.">
      <ButtonRipple @click="setLogin">팀관리 바로가기</ButtonRipple>
    </NoData>
    <button v-if="isLogin" class="btn-more" @click="moreTeamToMove">더보기</button>
  </div>
</template>

<script>
import { historyMove } from '@shared/utils/routerUtils';
import { getter } from '@shared/utils/storeUtils';
import { MAIN_URL } from '@/env';
import NoData from '@/views/components/common/NoData.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import MyTeamList from './MyTeamList.vue';
import MyTeamToggleTab from './MyTeamToggleTab.vue';

export default {
  name: 'MyTeam',
  components: { NoData, ButtonRipple, MyTeamToggleTab, MyTeamList },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    histories() {
      return this.teams[this.selectedIndex].histories;
    },
  },
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async setLogin() {
      if (!this.isLogin) return this.$services.auth.oAuthLogin(true, `${MAIN_URL}/user/ri/${this.$route.params.id}/me/teams`);
      return this.moreTeamToMove();
    },
    moreTeamToMove() {
      historyMove(`${MAIN_URL}/user/ri/${this.$route.params.id}/me/teams`);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-team] {.rel;
  .list-container {
    .list-contents {.w(100%);.bgc(@asset-bg-grey);.p(20, 8, 12, 8);.br-b(8);
      > li {.rel;.-b(#d5d5de);.fs(16, 48px);.h(48);.p(0, 8);.pr(90);.c(#787781);.ellipsis(1); transition: background-color 0.25s;
        &:last-child {.-b;}
        &:hover {.bgc(#e5e5ea);}
        //&:active {.bgc(#e0e0e6);}
        > span {.abs;.rt(8, 0);z-index: 1;.fs(14);.c(#a4a3ae);}
      }
    }
    [no-data] {.br-b(8);}
  }
  [no-data] {
    > button {.wh(147, 40);.fs(14);.c(#fff);background: linear-gradient(135deg, #2c81ff 0%, #b0aaff 100%);border-radius: 24px;}
  }
  .btn-more {.wh(63, 30);.br(30);.c(#787781);.bgc(@asset-bg-grey);.abs;.rt(10, -44);}

  @media (@tl-up) {
    .list-container {
      .list-contents {.p(28, 14, 12, 14);}
    }
    [no-data] {
      > button {.wh(147, 48);.fs(16);}
    }
    .btn-more {.wh(63, 40);.br(30);.c(#787781);.bgc(@asset-bg-grey);.abs;.rt(0, -94);}
  }

  @media (@ds-up) {
    .list-container {
      .list-contents {.p(28, 14, 12, 14);}
    }
  }

  // @media (max-width: @screen-tv-min){
  //   [team-container]{
  //     .team-list{
  //       > div{
  //         .btn-direct{.bgc(#EBEBF0); }
  //       }
  //     }
  //   }
  // }
}
</style>
